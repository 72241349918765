<template>
  <div>
    <div class="feed-body">
      <div class="feed-contents quillWrapper" v-if="event">
        <div>
          <v-parallax :src="this.event.cover" alt="" width="100%" />
        </div>
        <div class="d-flex">
          <div class="event-details-section">
            <div class="d-flex align-center">
              <div class="pt-10">
                <div style="padding-left: 16px;">
                  {{
                    new Date(this.event.createdAt).toLocaleString("en-us", {
                      dateStyle: "long",
                    })
                  }}
                </div>
                <h2 class="event-title">{{ this.event.title }}</h2>
                <div class="mb-10">
                  <v-chip-group column>
                    <v-chip
                      v-for="category in event.categories"
                      :key="category"
                      @click="goToCategory(category)"
                    >
                      {{ category }}
                    </v-chip>
                  </v-chip-group>
                </div>
              </div>
              <v-spacer />
              <v-tooltip bottom v-if="profileType == 'Admin'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    style="border-radius: 1px; border-color: #6038F7; border-style: solid; border-radius: 36px;"
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="onEditEvent"
                    class="mr-2"
                  >
                    <v-icon color="#6038F7" style="margin-top: 2px;" size="18">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                Edit Event
              </v-tooltip>
              <v-tooltip bottom v-if="profileType == 'Admin'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    style="border-radius: 1px; border-color: #6038F7; border-style: solid; border-radius: 36px; margin-right: 30px;"
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="onDeleteEvent"
                  >
                    <v-icon color="#6038F7" style="margin-top: 2px;" size="18">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                Delete Event
              </v-tooltip>
            </div>
            <div class="post-content ql-editor" v-html="this.event.html" />
          </div>
          <div class="hidden-sm-and-down right-section">
            <div
              color="white"
              class="mt-5 pb-5"
              style="width: 230px; max-width: 230px"
            >
              <div style="min-height: 400px;">
                <div
                  class="d-flex"
                  style="justify-content: flex-end; width: 100%"
                >
                  <v-btn text class="mb-3" @click="goToEventHome"
                    >See All</v-btn
                  >
                </div>
                <div v-for="et in filteredEvents" :key="et._id" class="mb-10">
                  <div style="width: 100%" @click="goToDetails(et._id)">
                    <div class="d-flex flex-column">
                      <div style="width: 230px; height: 230px;">
                        <v-img :src="et.cover" width="100%" height="100%" />
                      </div>
                      <div
                        style="word-break: break-word; margin-left: 12px; font-weight: bold;"
                        class="url-button"
                      >
                        {{ et.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="d-flex align-center justify-center"
        style="height: 100%; width: 100%"
      >
        <v-progress-circular indeterminate color="amber" class="mt-6" />
      </div>
      <div
        class="hidden-md-and-up"
        style="width: 100%; padding-left: 50px; padding-right: 50px;"
      >
        <div
          color="white"
          class="mt-5 pb-5 d-flex flex-colum align-center justify-center"
          style="width: 100%"
        >
          <div style="min-height: 400px;">
            <div
              class="d-flex"
              style="justify-content: flex-start; width: 100%"
            >
              <v-btn text class="mb-3" @click="goToEventHome">See All</v-btn>
            </div>
            <div v-for="et in filteredEvents" :key="et._id" class="mb-10">
              <div style="width: 100%" @click="goToDetails(et._id)">
                <div class="d-flex flex-column">
                  <div
                    style="width: 400px; height: 230px; padding-right: 20px; padding-left: 20px;"
                  >
                    <v-img :src="et.cover" width="100%" height="100%" />
                  </div>
                  <div
                    style="word-break: break-word; margin-left: 12px; font-weight: bold;"
                    class="url-button"
                  >
                    {{ et.title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <confirm-delete-dialog
        :deleteDialog="deleteDialog"
        :loading="loading"
        :onClose="onCloseDialog"
        :onConfirm="onConfirmDelete"
      />
    </div>
    <bottom-bar v-if="event" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
import ConfirmDeleteDialog from "@/components/ConfirmDeleteDialog.vue";
import BottomBar from "../../components/core/BottomBar.vue";
export default {
  components: { ConfirmDeleteDialog, BottomBar },
  name: "EventDetails",
  data() {
    return {
      event: null,
      deleteDialog: false,
      loading: false,
      filteredEvents: [],
    };
  },
  methods: {
    ...mapActions("event", {
      getEventDetails: "getEventDetails",
      deleteEvent: "deleteEvent",
      fetchAllEvents: "fetchAllEvents",
    }),
    onEditEvent() {
      this.$router.push({ name: "edit-event", query: { _id: this.event._id } });
    },
    goToAgencyProfile() {
      this.$router.push({
        name: "agency-profile-details",
        params: { id: this.event.poster },
      });
    },
    onDeleteEvent() {
      this.deleteDialog = true;
    },
    onCloseDialog() {
      this.deleteDialog = false;
    },
    onConfirmDelete() {
      this.loading = true;
      this.deleteEvent({ _id: this.event._id })
        .then(() => {
          this.loading = false;
          this.$router.back();
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            this.snackbar = true;
            this.errorMessage = error.response.data.message;
          } else {
            this.snackbar = true;
            this.errorMessage = error;
          }
        });
    },
    loadData() {
      this.getEventDetails({ _id: this.$route.query._id })
        .then((res) => {
          this.event = res;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    loadEvents() {
      this.loading = true;
      this.fetchAllEvents()
        .then((res) => {
          this.loading = false;
          this.filteredEvents = res.filter(
            (e) => e._id != this.$route.query._id
          );
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    goToDetails(_id) {
      if (this.$route.name != "event-details" || this.$route.query._id != _id) {
        this.$router.push({ name: "event-details", query: { _id } });
      }
    },
    goToEventHome() {
      this.$router.push({ name: "event-home" });
    },
    goToCategory(category) {
      this.$router.push({
        name: "event-search",
        query: { category },
      });
    },
  },
  computed: {
    ...mapGetters({
      profile: "auth/getProfile",
      profileType: "auth/getType",
    }),
  },
  watch: {
    "$route.query._id": function(newValue) {
      if (newValue) {
        this.event = null;
        this.loadData();
        this.loadEvents();
        this.filteredEvents = this.events.filter(
          (e) => e._id != this.$route.query._id
        );
      }
    },
  },
  mounted() {
    this.loadData();
    this.loadEvents();
  },
};
</script>
<style scoped>
.feed-body {
  width: 100%;
  min-width: 200px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.feed-contents {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
}
.event-title {
  color: #7024c4;
  margin-bottom: 10px;
  padding-top: 10px;
  margin-left: 15px;
}
div >>> .feed-contents p img {
  max-width: 100%;
}

.feed-contents h4 {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 24px;
}

.event-details-section {
  flex-grow: 1;
  max-width: calc(100vw - 300px);
  padding-left: 20px;
}

.right-section {
  overflow-y: hidden;
  position: sticky;
  min-height: inherit;
  overflow-x: hidden;
  top: 118px;
  background: #f4f9ff;
  width: 280px;
  min-width: 280px;
  padding-left: 25px;
  border-left: 1px solid lightgray;
  margin-left: 25px;
  margin-top: 25px;
}
.right-section:hover {
  overflow-y: auto;
}
.right-section::-webkit-scrollbar {
  width: 8px;
  transition-duration: 0.5s;
}

/* Track */
.right-section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.right-section::-webkit-scrollbar-thumb {
  background: rgba(240, 160, 0, 0.3);
  border-radius: 3px;
}

/* Handle on hover */
.right-section::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}

@media only screen and (max-width: 600px) {
  .feed-contents {
    padding-left: 0px;
    padding-right: 0px;
  }
  .event-details-section {
    flex-grow: 1;
    max-width: 100%;
    padding-left: 20px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .feed-contents {
    padding-left: 20px;
    padding-right: 20px;
  }
  .event-details-section {
    flex-grow: 1;
    max-width: 100%;
    padding-left: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .feed-contents {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media only screen and (min-width: 960px) {
}
</style>
